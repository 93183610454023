<template>
  <v-app>
    <template v-if="!loading">
      <Default v-if="!isAuthenticated" />
      <Authenticated v-else />
    </template>
  </v-app>
</template>

<script>
import { useAccountStore } from "@/store/account";
import { useAppStore } from "@/store/app";
import { mapState } from "pinia";
import Default from "./Default.vue";
import Authenticated from "./Authenticated.vue";

export default {
  components: {
    Default,
    Authenticated,
  },
  data() {
    return {
      accountStore: null,
      appStore: null,
      loading: true,
      isAuthenticated: false,
    };
  },
  computed: {
    ...mapState(useAppStore, ["error"]),
  },
  mounted() {
    this.accountStore = useAccountStore();
    this.appStore = useAppStore();

    // Ensure authentication status is determined before rendering
    this.checkAuth();
  },
  methods: {
    checkAuth() {
      if (typeof window !== "undefined") {
        this.isAuthenticated = !!window.sessionStorage.getItem("user");
      }
      this.loading = false;
    },
  },
};
</script>
